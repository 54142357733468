@import '../../utilities/colours.scss';

.portfolio-page {
    width: 100vw;
    height: 100vh;
    background-repeat: repeat;
    background-size: cover;
    background-position: 50%;

    ul {
        text-align: left;
    }

    :link {
        color: white;
    }

    :visited {
        color:white;
    }
}

.link-fix {
    color: aquamarine;

    :visited {
        color:lightblue;
    }
}

.normal-text {
    font-size: small;
    height: fit-content;
    vertical-align: bottom;
}

.portfolio-overlay {
    background-color: #00000088;
    position: fixed;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    z-index: 1;
}

.portfolio-overlay-text {
    position: fixed;
    background-color: $primary-dark;
    color: $primary-light;
    opacity: 80%;
    width: 80%;
    min-height: 60%;
    max-height: 80%;
    max-width: 700px;
    top: 12%;
    left: calc(10% - 20px);
    padding: 20px;
    padding-top: 0px;
    font-size: large;
    overflow-y: scroll;
}

.portfolio-overlay-button {
    position: absolute;
    background: linear-gradient(180deg, $accent, $accent-fade);
    color: $primary-light;
    left: 8%;
    width: 80%;
    top: calc(12% + 80%);
    left: calc(10% - 20px);
    padding: 10px 20px;
    border-radius: 10px;
    font-size: larger;
    overflow-y: scroll;
    text-align: center;
    vertical-align: middle;
    cursor: pointer;
}

.portfolio-text-container {
    position: fixed;
    background-color: $primary-dark;
    color: $primary-light;
    opacity: 80%;
    left: 8%;
    width: 80%;
    min-height: 60%;
    max-height: 80%;
    top: 12%;
    left: calc(10% - 20px);
    padding: 20px;
    padding-top: 0px;
    font-size: large;
    overflow-y: scroll;
}

.no-space {
    display: inline-block;
}

.portfolio-button-container {
    position: fixed;
    background: linear-gradient(180deg, $accent, $accent-fade);
    color: $primary-light;
    left: 8%;
    width: 80%;
    top: calc(12% + 80%);
    left: calc(10% - 20px);
    padding: 10px 20px;
    border-radius: 10px;
    font-size: larger;
    overflow-y: scroll;
    text-align: center;
    vertical-align: middle;
    cursor: pointer;
    z-index: 3;
}

.project-container {
    position: fixed;
    background-color: $primary-dark;
    color: $primary-light;
    opacity: 80%;
    left: 8%;
    width: 80%;
    min-height: 60%;
    max-height: 80%;
    top: 12%;
    left: calc(10% - 20px);
    padding: 20px;
    padding-top: 0px;
    font-size: large;
    overflow-y: scroll;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-content: flex-start;
}

.language-list
{
    display: flex;
    flex-direction: row;
    vertical-align: middle;
    padding-top: 4px;
    margin-bottom: -8px;
    justify-content: center;
    column-gap: 20px;
}

.darken-background
{
    background-color: #000000AA;
    box-shadow: 0 0 8px 8px #000000AA;
    height: 100%;
    width: 100%;
}

.project-title {
    height: fit-content;
    flex-basis: 100%;
}

.project {
    text-decoration: none;
    font-weight: 600;
    text-shadow: 0px 3px black;
    margin: 5px;
    background-color: green;
    width: 300px;
    min-height: 180px;
    flex-grow: 1;
    max-width: 360px;
    font-size: larger;
    padding: 10px;
    background-size: cover;
    background-position: 50%;
    box-shadow: 0 0 8px 8px $primary-dark inset;
    cursor: pointer;
    animation: normal fadein 0.4s;
}

@keyframes fadein {
    from { filter: blur(5px); }
    to  { filter: blur(0px); }
}

.project:hover {
    transform: scale(1.05);
}

.title {
    font-size: x-large;
}

@media screen and (min-width: 600px) {
    .portfolio-text-container {
        width: 40%;
        height: 100%;
        max-height: 100%;
        left: 8%;
        top: 0px;
        padding-top: 100px;
    }
}

