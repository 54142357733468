@use 'sass:color';
@use 'sass:map';
@use 'sass:meta';

$accent: #d68f24 !default;
$accent-fade: #cf3d62 !default;
$secondary: #c648d1 !default;
$secondary-fade: #4237df !default;
$primary-light: #f3f3f3 !default;
$primary-dark: rgb(55, 59, 65) !default;

$tertiary: #4aa54f;
$tertiary-fade: #2a648a;

:export {
    accent: $accent;
    accentFade: $accent-fade;
    secondary: $secondary;
    primaryLight: $primary-light;
    primaryDark: $primary-dark;
    tertiary: $tertiary;
    tertiaryFade: $tertiary-fade;
}
