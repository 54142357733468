@import 'utilities/colours.scss';

.App {
  text-align: center;
  height: 100vh;
}

.App-header {
  //Alignment
  display: flex;
  flex-direction: row;
  //Header stuff
  position: fixed;
  width: 100%;
  height: 70px;
  //Style
  background-color: $primary-dark;
  color: $primary-light;
  z-index: 5;
}

.Header-button {
  width: 130px;
  height: 50px;
  line-height: 50px;
  margin: 10px;

  font-size: 1.2em;
  color: $primary-light;
  //padding: 12px;
  text-decoration: none;
  font-weight: 600;
  transition: 0.1s;

  border-radius: 15px;

  cursor: pointer;

  &:hover {
    font-size: 1.4em;
    background-color: lighten($primary-dark, $amount: 8);
  }

  &.selected {
    border-bottom-style: solid;
    border-bottom-width: 3px;
    border-bottom-color: #fff;
  }
}

.App-body {
  min-height: 100%;
  background-color: $primary-light;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: $primary-dark;
}

.App-link {
  background: -webkit-linear-gradient(45deg, $accent, $accent-fade);
  background-clip: text;
  -webkit-text-fill-color: transparent;
  border: solid;
  border-image: linear-gradient(45deg, $accent, $accent-fade);
  border-image-slice: 1 1;
  border-width: 8px;
  padding: 12px;
  border-radius: 20px;
  text-decoration: none;
  font-weight: 400;
  transition: 0.3s;
  min-width: 250px;
  margin-left: 20px;
  margin-right: 20px;

  &:hover {
    background-clip: initial;
    -webkit-text-fill-color: $primary-light;
  }
}

.Horizontal-buttons {
  display: inline;
  padding-top: 30px;
  white-space: nowrap;
}
