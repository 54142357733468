@import '../utilities/colours.scss';

.home-background {
  position: fixed;
  background-image: -webkit-linear-gradient(-30deg, $accent, $accent-fade);
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  color: $primary-dark;
}

.home-content-container {
  background-image: none;
  background-color: adjust-color($color: $primary-light, $alpha: -0.8);
  height: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding-left: 80px;
  padding-right: 80px;
}

.home-content {
  background-color: $primary-light;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;
  padding-left: calc(min(10vw, 70px));
  padding-right: calc(min(10vw, 70px));
}

.background-svg-holder {
  position: absolute;
  height: calc(max(1024, 100%));
  right: 0;
  bottom: 0;
  z-index: -1;
}

.scrolling-text-holder {
  display: inline-block;
  color: $accent;
  font-weight: 600;
  height: 1.5em;
  overflow-y: hidden;
  transform: translateY(0.4em);
}
  
.scrolling-text {
  animation: text-scroll 5s infinite;
  animation-fill-mode: forwards;
}

@keyframes text-scroll {
  0% { transform: translateY(0px); color: $accent; }
  28% { transform: translateY(0px); color: $accent; }
  33% { transform: translateY(-25%); color: $accent-fade; }
  61% { transform: translateY(-25%); color: $accent-fade; }
  66% { transform: translateY(-50%); color: $secondary; }
  95% { transform: translateY(-50%); color: $secondary; }
  100% { transform: translateY(-75%); color: $accent; }
}

.animation-container {
  position: absolute;
  left: 0;
  top: 0;
  width: 100vw;
  height: 100vh;
  overflow-x: hidden;
  overflow-y: hidden;
  pointer-events: none;
}

.enter-animation {
  animation: enterAnimation 1s 1;
  position: absolute;
  width: 120vw;
  height: 200vh;
  background-color: $primary-dark;
  z-index: 2;
  visibility: hidden;
  pointer-events: inherit;
}

@keyframes enterAnimation {
  0% { transform: translateX(0%); visibility: visible; }
  10% { transform: rotate(-1deg); }
  100% { transform: translateX(-120%) rotate(-20deg); visibility: visible; opacity: 90%; }
}

.bottom-text {
  position: absolute;
  font-size: 20px;
  bottom: 20px;
  height: 30px;
}
